import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
    tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appImages } from "../../../../assets";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import CanMultipleSelect from "../../../../components/CanSelectMultiple";
import CustomButton from "../../../../components/CustomButton";
import ImageUploader from "../../../../components/ImgeComponent";
import Loader from "../../../../components/Loader";
import QuantityCounter from "../../../../components/QuantityCounter";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import StackTemplate from "../../../../components/StackTemplate";
import Switch from "../../../../components/Switch";
import TextInput from "../../../../components/TextInput";
import TextLabel from "../../../../components/TextLabel";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import { updateUserProfile } from "../../../../service/authentiction";
import { fetchSetting } from "../../../../service/common";
import ColorPickerComponent from "./../../../../components/ColorPicker";
import HelpDialog from "./../../../../components/HelpDialog";
import AllComments from "./AllComments";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    backgroundColor: "#1B1D28",
    textAlign: "center",
    padding: "3px 5px",
    fontSize: 10,
    fontWeight: 300,
  },
}));

const colorsss = [
  "#161616",
  "#5A663E",
  "#798952",
  "#FB905C",
  "#FEB761",
  "#A6BFB9",
  "#F7E580",
  "#13E9F8",
  "#C5D6F1",
  "#ffffff",
];

const getColorStyle = (currentColor, appColor) => {
  return currentColor === "#161616" ||
    currentColor === "#5A663E" ||
    currentColor === "#798952"
    ? "#fff"
    : appColor.black;
};

function FilterFacebookComments() {

  const imgRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalClass = GlobalStyle();
  const [winnersCount, setWinnersCount] = useState(1);
  const [substitutesCount, setSubstitutsCount] = useState(0);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRelaod, setIsReload] = useState(false);
  const [isHashtag, setIsHashtag] = useState(false);
  const [isExtraChance, setIsExtraChance] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [hashtagText, setHashtagText] = useState("");
  const [extraChanceNames, setExtraChanceNames] = useState([]);
  const [blockListNames, setBlockListNames] = useState([]);
  const [contentTitle, setContentTitle] = useState("");
  const [settingData, setSettingData] = useState(null);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [multiOptions, setMultiOptions] = useState([]);
  const [isResponse, setIsResponse] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const {
    setWin,
    setSubs,
    countDown,
    setCountDown,
    currentColor,
    setCurrentColor,
    setImageLoaing,
    setUserData,
    postData,
    setPostData,
  } = useContext(AppContext);

  console.log(postData, "postDatapostDatapostData");

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleIncreaseWinners = () => {
    setWinnersCount(parseInt(winnersCount) + 1);
  };

  useEffect(() => {
    if (filterData?.actionType == "edit") {
      handleReloadComments();
      if (isResponse) {
        setIsDuplicate(filterData?.dublicate);
        setIsHashtag(filterData?.hashtag);
        setIsBlock(filterData?.block);
        setHashtagText(filterData?.hashtagText);
        setIsExtraChance(filterData?.extraChance);
        setCountDown(filterData?.counter);
        setWinnersCount(filterData?.totalWinners);
        setSubstitutsCount(filterData?.totalSubstitutes);
        setTimeout(() => {
          setExtraChanceNames(filterData?.extraChanceNames);
          setBlockListNames(filterData?.blockListNames);
        }, 1500);
      }
    }
  }, [isResponse]);

  useEffect(() => {
    getSettingData();
    setContentTitle(postData?.caption);
    let tempArr = filterData?.allComments?.map((data) => {
      return { value: data?.name, label: data?.name };
    });
    setMultiOptions(tempArr);
  }, []);

  useEffect(() => {
    setBlockListNames([]);
  }, [isBlock]);

  useEffect(() => {
    setExtraChanceNames([]);
  }, [isExtraChance]);

  // useEffect(() => {
  //   setHashtagText("");
  // }, [isHashtag]);

  const getSettingData = () => {
    setIsLoading(true);
    fetchSetting()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setSettingData(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleDecreaseWinners = () => {
    if (winnersCount <= 1) {
      handleSnackbarVal(true, "error", "Winners can not be Zero");
    } else {
      setWinnersCount(parseInt(winnersCount) - 1);
    }
  };

  const handleChangeWinnerNumber = (e) => {
    let parseInNumber = parseInt(e.target.value);
    if (parseInNumber === 0) {
      handleSnackbarVal(true, "error", "Winners can not be Zero");
    } else {
      setWinnersCount(parseInNumber);
    }
  };

  const handleIncreaseSubs = () => {
    setSubstitutsCount(parseInt(substitutesCount) + 1);
  };

  const handleDecreaseSubs = () => {
    if (substitutesCount <= 0) {
      handleSnackbarVal(true, "error", "Substitute can not be less than Zero");
    } else {
      setSubstitutsCount(parseInt(substitutesCount) - 1);
    }
  };

  const handleChangeSubsNumber = (e) => {
    let parseInNumber = parseInt(e.target.value);
    if (parseInNumber < 0) {
      handleSnackbarVal(true, "error", "Substitute can not be less than Zero");
    } else {
      setSubstitutsCount(parseInNumber);
    }
  };

  const handleImageChange = (event) => {
    setImageLoaing(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "b5kg6fiz");
    formData.append("cloud_name", "dx5ph9sov");

    axios({
      method: "post",
      url: "https://api.cloudinary.com/v1_1/dx5ph9sov/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        updateUserProfile(userData?._id, {
          profileImage: res?.data?.url,
        }).then((ress) => {
          setImageLoaing(false);
          if (ress?.data?.status == "ok") {
            let data = ress?.data?.data;
            setUserData(data);
            localStorage.setItem("user", JSON.stringify(data));
          }
        });
        handleSnackbarVal(true, "success", "Image updated successfully");
      })
      .catch((error) => {
        setImageLoaing(false);
        handleSnackbarVal(true, "error", error?.message);
      });
  };

  const handleReloadComments = (data) => {
    // setIsReload(true);
    // fetchAllTwitterReply({ tweetId: tweetData?.tweetId })
    //   .then((response) => {
    //     if (response?.data?.status === "ok") {
    //       let reply = response?.data?.data;
    //       dispatch(setAppFilters({ ...filterData, allComments: reply }));
    //       let tempArr = reply?.map((data) => {
    //         return { value: data?.name, label: data?.name };
    //       });
    //       setMultiOptions(tempArr);
    //       setIsResponse(true);
    //       setIsReload(false);
    //     } else {
    //       handleSnackbarVal(true, "error", response?.data?.message);
    //     }
    //   })
    //   .catch((resError) => {
    //     setIsReload(false);
    //     handleSnackbarVal(true, "error", "Something went wrong");
    //   });
    setIsReload(true);
    setTimeout(() => {
      setIsReload(false);
    }, 2000);
  };

  const selectRandomWinnersSubs = (array, count) => {
    let tempArr = [];
    let tempArr2 = [...array];
    for (let i = 0; i < count; i++) {
      let key = Math.floor(Math.random() * tempArr2.length);
      let result = tempArr2[key];
      tempArr.push(result);
      tempArr2.splice(key, 1);
    }
    return tempArr;
  };

  const handleSelectWinners = () => {
    let extraChanceArr = extraChanceNames?.map((data) => {
      const { label } = data;
      return label;
    });

    const newExtraChanceArr = filterData?.allComments?.filter((dataa) =>
      extraChanceArr.includes(dataa?.name)
    );

    let temArray = [];

    if (newExtraChanceArr?.length > 1) {
      temArray = newExtraChanceArr;
    } else {
      temArray = filterData?.allComments;
    }

    if (isBlock) {
      let blockNames = blockListNames?.map((data) => {
        const { label } = data;
        return label;
      });
      temArray = temArray?.filter((dataa) => !blockNames.includes(dataa?.name));
    }

    if (isHashtag && hashtagText?.length > 0) {
      const hashtagsWithSpaces = hashtagText
        .toLowerCase()
        .split(" ")
        .filter((item) => item.trim() !== "")
        .map((data) => {
          if (data?.includes("#")) {
            return data?.slice(1);
          } else {
            return data;
          }
        });

      const hashtagsFilter = temArray.filter((comment) =>
        comment.hashtags.some((hashtag) => hashtagsWithSpaces.includes(hashtag))
      );

      temArray = hashtagsFilter;
    }

    if (isDuplicate) {
      const uniqueUserNames = new Set();
      const filteredArr = [];

      temArray.forEach((item) => {
        const userName = item?.userName;
        if (userName && !uniqueUserNames.has(userName)) {
          uniqueUserNames.add(userName);
          filteredArr.push(item);
        }
      });

      temArray = filteredArr;
    }

    if (temArray?.length >= winnersCount + substitutesCount) {
      const finalWinners = selectRandomWinnersSubs(temArray, winnersCount);

      const newFinalWinners = finalWinners.map((data) => {
        return data.userName;
      });

      const finalAlternatives = temArray.filter(
        (dataa) => !newFinalWinners.includes(dataa?.userName)
      );

      const finalSubstitutes = selectRandomWinnersSubs(
        finalAlternatives,
        substitutesCount
      );

      let params = {
        hashtagText,
        dublicate: isDuplicate,
        hashtag: isHashtag,
        block: isBlock,
        extraChance: isExtraChance,
        extraChanceNames: extraChanceNames,
        blockListNames: blockListNames,
        counter: countDown,
        totalWinners: winnersCount,
        totalSubstitutes: substitutesCount,
      };

      dispatch(setAppFilters({ ...filterData, ...params }));
      setSubs(finalSubstitutes);
      setWin(finalWinners);

      let newObj = { ...postData, caption: contentTitle };
      setPostData(newObj);
      localStorage.setItem("facebook-post", JSON.stringify(newObj));
      navigate("/facebook-giveaway/winners");
    } else {
      handleSnackbarVal(
        true,
        "error",
        "Based on given condition winners can not be selected"
      );
    }
  };

  const handleChangeCounters = (e, action) => {
    if (userData?.countDown) {
      if (action === "increase") {
        setCountDown(countDown + 1);
      } else if (action === "decrease") {
        if (countDown <= 5) {
          handleSnackbarVal(
            true,
            "error",
            "Countdown can not be less than five"
          );
        } else {
          setCountDown(countDown - 1);
        }
      } else if (action === "counter") {
        let parseInNumber = parseInt(e.target.value);
        if (parseInNumber < 5) {
          handleSnackbarVal(
            true,
            "error",
            "Count Down can not be less than Five"
          );
        } else {
          setCountDown(parseInNumber);
        }
      }
    } else {
      handleSnackbarVal(true, "error", "Upgrade your plan to use this feature");
    }
  };

  return (
    <AppHeader>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Divider />
      <Loader isloading={isLoading} />
      <Container
        sx={{
          maxWidth: { lg: "1250px !important", xl: "1500px !important" },
          marginTop: "24px",
        }}
      >
        <Grid container spacing={10}>
          <Grid item xs={12} md={4} xl={4}>
            <Box bgcolor={currentColor} className={globalClass.setMainCon}>
              <StackTemplate stackProps={{ padding: "12px" }}>
                <ImageUploader
                  picture={
                    userData?.profileImage
                      ? userData?.profileImage
                      : appImages.emojiAvatar
                  }
                  alt={"Emoji"}
                />
                <Typography
                  variant={"h1"}
                  color={getColorStyle(currentColor, appColor)}
                  className={globalClass.settingHeader}
                >
                  {settingData?.heading1}
                </Typography>
                <BootstrapTooltip title={"Need help ?"}>
                  <HelpOutlineIcon
                    onClick={() => setOpenHelpDialog(true)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "20px",
                      color: getColorStyle(currentColor, appColor),
                    }}
                  />
                </BootstrapTooltip>
              </StackTemplate>
              <Divider />
              <Box p={"12px"}>
                <Typography
                  variant={"body1"}
                  fontSize={13}
                  mb={"2px"}
                  color={getColorStyle(currentColor, appColor)}
                >
                  Title
                </Typography>

                <TextField
                  type={"text"}
                  value={contentTitle}
                  size={"small"}
                  fullWidth
                  onChange={(e) => setContentTitle(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: getColorStyle(currentColor, appColor),

                      "&.Mui-focused fieldset": {
                        border: "1px solid",
                        borderColor: getColorStyle(currentColor, appColor),
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: getColorStyle(currentColor, appColor),
                    },
                  }}
                />
                <StackTemplate stackProps={{ mt: 3 }}>
                  <Typography
                    variant={"body1"}
                    fontSize={13}
                    fontWeight={400}
                    color={getColorStyle(currentColor, appColor)}
                  >
                    # Winners
                  </Typography>
                  <QuantityCounter
                    selectedQty={winnersCount}
                    handleChangeCounters={handleChangeWinnerNumber}
                    handleIncrease={handleIncreaseWinners}
                    handleDecrease={handleDecreaseWinners}
                  />
                </StackTemplate>
                <StackTemplate stackProps={{ mt: 1 }}>
                  <Typography
                    variant={"body1"}
                    fontSize={13}
                    fontWeight={400}
                    color={getColorStyle(currentColor, appColor)}
                  >
                    # Substitutes
                  </Typography>
                  <QuantityCounter
                    selectedQty={substitutesCount}
                    handleChangeCounters={handleChangeSubsNumber}
                    handleIncrease={handleIncreaseSubs}
                    handleDecrease={handleDecreaseSubs}
                  />
                </StackTemplate>
                {userData?.duplicate && (
                  <StackTemplate stackProps={{ mt: 1 }}>
                    <Typography
                      variant={"body1"}
                      fontSize={13}
                      fontWeight={400}
                      color={getColorStyle(currentColor, appColor)}
                    >
                      Exclude Duplicates
                    </Typography>
                    <Switch
                      value={isDuplicate}
                      setValue={setIsDuplicate}
                      checkActive={userData?.duplicate ? true : false}
                      handleSnackbarVal={handleSnackbarVal}
                    />
                  </StackTemplate>
                )}
                {userData?.hashtag && (
                  <StackTemplate stackProps={{ mt: 1 }}>
                    <Typography
                      variant={"body1"}
                      fontSize={13}
                      fontWeight={400}
                      color={getColorStyle(currentColor, appColor)}
                    >
                      Filter by #Hashtag
                    </Typography>
                    <Switch
                      value={isHashtag}
                      setValue={setIsHashtag}
                      checkActive={userData?.hashtag ? true : false}
                      handleSnackbarVal={handleSnackbarVal}
                    />
                  </StackTemplate>
                )}
                {isHashtag && (
                  <Box mt={1}>
                    <TextLabel
                      textSize={"10px"}
                      inputText={"Separate hashtags with spaces"}
                    />
                    <TextInput
                      placeholder={"Write a #hashtag"}
                      inputVal={hashtagText}
                      handleChangeInput={(e) => setHashtagText(e.target.value)}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <Grid container>
              <Grid item xs={12}>
                <Box bgcolor={currentColor} className={globalClass.setMainCon}>
                  <StackTemplate stackProps={{ padding: "12px" }}>
                    <Typography
                      variant={"h1"}
                      className={globalClass.settingHeader}
                      color={getColorStyle(currentColor, appColor)}
                    >
                      {settingData?.heading2}
                    </Typography>
                    {!userData?.extraChance && !userData?.blockList ? (
                      <BootstrapTooltip
                        title={"Upgrade your plan to use this feature"}
                      >
                        <LockIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "20px",
                            color: getColorStyle(currentColor, appColor),
                          }}
                        />
                      </BootstrapTooltip>
                    ) : null}
                  </StackTemplate>
                  <Divider />
                  <Box p={"12px"}>
                    <StackTemplate stackProps={{ mt: 1 }}>
                      <Typography
                        variant={"body1"}
                        fontSize={13}
                        fontWeight={400}
                        color={getColorStyle(currentColor, appColor)}
                      >
                        Bonus & Extra Chances
                      </Typography>
                      <Switch
                        value={isExtraChance}
                        setValue={setIsExtraChance}
                        checkActive={userData?.extraChance ? true : false}
                        handleSnackbarVal={handleSnackbarVal}
                      />
                    </StackTemplate>

                    {isExtraChance && (
                      <Box mt={1}>
                        <CanMultipleSelect
                          state={extraChanceNames}
                          setState={setExtraChanceNames}
                          options={multiOptions}
                        />
                      </Box>
                    )}
                    <StackTemplate stackProps={{ mt: 1 }}>
                      <Typography
                        variant={"body1"}
                        fontSize={13}
                        fontWeight={400}
                        color={getColorStyle(currentColor, appColor)}
                      >
                        Block List
                      </Typography>
                      <Switch
                        value={isBlock}
                        setValue={setIsBlock}
                        checkActive={userData?.blockList ? true : false}
                        handleSnackbarVal={handleSnackbarVal}
                      />
                    </StackTemplate>
                    {isBlock && (
                      <Box mt={1}>
                        <CanMultipleSelect
                          state={blockListNames}
                          setState={setBlockListNames}
                          options={multiOptions}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Box
                  bgcolor={currentColor}
                  borderRadius={"20px"}
                  boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
                >
                  <StackTemplate stackProps={{ p: "12px" }}>
                    <Typography
                      variant={"h1"}
                      className={globalClass.settingHeader}
                      color={getColorStyle(currentColor, appColor)}
                    >
                      {settingData?.heading3}
                    </Typography>
                    {!userData?.branding && !userData?.countDown ? (
                      <BootstrapTooltip
                        title={"Upgrade your plan to use this feature"}
                      >
                        <LockIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "20px",
                            color: getColorStyle(currentColor, appColor),
                          }}
                        />
                      </BootstrapTooltip>
                    ) : null}
                  </StackTemplate>
                  <Divider />
                  <Box p={"12px"}>
                    <StackTemplate stackProps={{ spacing: 1 }}>
                      <Stack direction={"column"}>
                        <Typography
                          variant={"body1"}
                          fontSize={12}
                          fontWeight={600}
                          color={getColorStyle(currentColor, appColor)}
                        >
                          Upload logo / Image
                        </Typography>
                        <Typography
                          variant={"body1"}
                          fontSize={11}
                          fontWeight={400}
                          color={getColorStyle(currentColor, appColor)}
                        >
                          Recommended size : 180 px x 50 px
                        </Typography>
                      </Stack>
                      <Button
                        variant={"outlined"}
                        sx={{
                          // color: appColor.black,
                          // borderColor: appColor.black,
                          fontSize: 12,
                          height: 34,
                          textTransform: "capitalize",
                          borderRadius: "10px",
                          color: getColorStyle(currentColor, appColor),
                          borderColor: getColorStyle(currentColor, appColor),

                          "&:hover": {
                            color: getColorStyle(currentColor, appColor),
                            borderColor: getColorStyle(currentColor, appColor),
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          if (userData?.branding) {
                            imgRef.current.click();
                          } else {
                            handleSnackbarVal(
                              true,
                              "error",
                              "Upgrade your plan to use this feature"
                            );
                          }
                        }}
                      >
                        Upload
                      </Button>
                      <input
                        type="file"
                        ref={imgRef}
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </StackTemplate>
                    <StackTemplate stackProps={{ mt: 1 }}>
                      <Typography
                        variant={"body1"}
                        fontSize={13}
                        fontWeight={400}
                        color={getColorStyle(currentColor, appColor)}
                      >
                        Countdown
                      </Typography>
                      <QuantityCounter
                        selectedQty={countDown}
                        handleIncrease={() =>
                          handleChangeCounters("_", "increase")
                        }
                        handleDecrease={() =>
                          handleChangeCounters("_", "decrease")
                        }
                        handleChangeCounters={(e) =>
                          handleChangeCounters(e, "counter")
                        }
                      />
                    </StackTemplate>
                    <>
                      <Typography
                        variant={"body1"}
                        fontSize={13}
                        fontWeight={600}
                        mt={1}
                        color={getColorStyle(currentColor, appColor)}
                      >
                        Main Color
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignContent={"center"}
                        justifyContent={"start"}
                        flexWrap={"wrap"}
                        mt={1}
                        gap={"8px"}
                      >
                        {colorsss?.map((colors) => {
                          return (
                            <Box
                              height={20}
                              width={20}
                              bgcolor={colors}
                              borderRadius={"5px"}
                              border={"1px solid #a9a6a6"}
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (userData?.branding) {
                                  setCurrentColor(colors);
                                  setShowColorPicker(true);
                                  // localStorage.setItem(
                                  //   "colorss",
                                  //   JSON.stringify(colors)
                                  // );
                                } else {
                                  handleSnackbarVal(
                                    true,
                                    "error",
                                    "Upgrade your plan to use this feature"
                                  );
                                }
                              }}
                            ></Box>
                          );
                        })}
                      </Stack>
                      <Box mt={"12px"} position={"relative"}>
                        {showColorPicker && (
                          <>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0px",
                                zIndex: "999",
                                padding: "0px",
                                left: "0px",
                              }}
                              onClick={() => setShowColorPicker(false)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                            <ColorPickerComponent
                              handleSnakbarVal={handleSnackbarVal}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                margin={"16px 0px"}
                sx={{ display: isExtraChance || isBlock ? "block" : "none" }}
              >
                <Box width={130} height={40} m={"0 auto"}>
                  <CustomButton
                    btnText={"Confirm"}
                    handleClickBtn={handleSelectWinners}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={0.75}></Grid> */}
          <Grid item xs={12} md={4} xl={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AllComments
                  heading4={settingData?.heading4 ? settingData?.heading4 : ""}
                  handleReloadComments={handleReloadComments}
                  isLoading={isRelaod}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          width={130}
          height={40}
          m={"0 auto"}
          mt={2}
          mb={2}
          sx={{ display: isExtraChance || isBlock ? "none" : "block" }}
        >
          <CustomButton
            btnText={"Confirm"}
            handleClickBtn={handleSelectWinners}
          />
        </Box>
      </Container>
      <HelpDialog open={openHelpDialog} setOpen={setOpenHelpDialog} />
      <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
        <AppFooter />
      </Container>
    </AppHeader>
  );
}

export default FilterFacebookComments;
