import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { appImages } from "../../../../assets";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import CustomButton from "../../../../components/CustomButton";
import LoaderScreen from "../../../../components/LoaderScreen";
import SoundWaveGif from "../../../../components/SoundWaveGif";
import { appColor } from "../../../../constants/Colors";
import { appFonts } from "../../../../constants/Fonts";
import { AppContext } from "../../../../context";
import "./style.css";

function FacebookWinnersAndSubstitutes() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { win, countDown, subs, congratulationData } = useContext(AppContext);

  console.log(win, "sdksdksdjsdjksasjkd");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, countDown * 1000);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const ButtonGroup = ({ arrayCount, next, previous, goToSlide, ...rest }) => {
    const [testCount, setTestCount] = useState(1);

    const handleInCreaseCount = () => {
      if (testCount === arrayCount) {
        setTestCount(1);
      } else {
        setTestCount(testCount + 1);
      }
    };

    const handleDeCreaseCount = () => {
      if (testCount === 1) {
        setTestCount(arrayCount);
      } else {
        setTestCount(testCount - 1);
      }
    };
    return (
      <div
        className="carousel-button-group"
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "14px",
        }}
      >
        {arrayCount > 1 && (
          <>
            <KeyboardArrowLeftIcon
              sx={{ color: "#000000", cursor: "pointer" }}
              onClick={() => {
                handleDeCreaseCount();
                previous();
              }}
            />
            <Typography>{testCount}</Typography>
            <KeyboardArrowRightIcon
              sx={{ color: "#000000", cursor: "pointer" }}
              onClick={() => {
                handleInCreaseCount();
                next();
              }}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <AppHeader>
      <Divider />
      <Container
        sx={{
          maxWidth: `${subs.length > 0 ? " 1250px" : "600px"} !important`,
        }}
      >
        {isLoading ? (
          <Box
            mt={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <LoaderScreen />
          </Box>
        ) : (
          <React.Fragment>
            <Box textAlign={"center"} mt={1}>
              <SoundWaveGif />
            </Box>
            <Typography
              mt={1}
              variant={"h2"}
              fontWeight={800}
              textAlign={"center"}
              letterSpacing={"4px"}
              fontFamily={appFonts.boldExtended}
              sx={{ fontSize: { xs: "18px", sm: "28px" } }}
            >
              {congratulationData?.heading ? congratulationData?.heading : ""}
            </Typography>
            {subs.length < 0 ? null : (
              <Typography
                variant={"body1"}
                fontSize={14}
                textAlign={"center"}
                mt={1}
              >
                {/* {congratulationData?.title} */}
                Your entry shines !! 🎉
              </Typography>
            )}
            <Grid container spacing={subs.length > 0 ? 3 : 0} mt={2}>
              <Grid item xs={12} md={subs.length > 0 ? 6 : 12}>
                {subs.length > 0 && (
                  <Typography
                    variant={"h6"}
                    fontSize={20}
                    fontWeight={600}
                    fontFamily={"Roboto Flex"}
                    textAlign={"center"}
                  >
                    Winners
                  </Typography>
                )}
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  arrows={false}
                  infinite={true}
                  responsive={responsive}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup arrayCount={win?.length} />}
                  keyBoardControl={true}
                  transitionDuration={500}
                  customTransition="transform 0.6s ease-in-out"
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {win?.map((dataa) => {
                    return (
                      <Box>
                        <Box
                          height={120}
                          width={120}
                          m={"30px auto"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          position={"relative"}
                          mb={"10px"}
                        >
                          <img
                            src={appImages.dottedLine}
                            width={"100%"}
                            className="rotating-box"
                            style={{ position: "absolute" }}
                          />
                          <Avatar
                            sx={{
                              height: 100,
                              width: 100,
                              position: "absolute",
                            }}
                            src={dataa?.image}
                          />
                        </Box>
                        <Typography
                          variant={"h6"}
                          fontSize={16}
                          fontWeight={600}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          {dataa?.name}
                          <a href={dataa?.authorUrl} target="_blank">
                            <LaunchIcon
                              sx={{
                                fontSize: 15,
                                color: appColor.black,
                                ml: "4px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </Typography>
                        <Typography
                          variant={"body1"}
                          fontSize={12}
                          textAlign={"center"}
                          mt={1}
                          sx={{ padding: { xs: "0px", sm: "0px 55px" } }}
                        >
                          {dataa?.text}
                        </Typography>
                      </Box>
                    );
                  })}
                </Carousel>
              </Grid>
              <Grid item xs={12} md={subs.length > 0 ? 6 : 12}>
                {subs.length > 0 && (
                  <Typography
                    variant={"h6"}
                    fontSize={20}
                    fontWeight={600}
                    fontFamily={"Roboto Flex"}
                    textAlign={"center"}
                  >
                    Substitutes
                  </Typography>
                )}
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  arrows={false}
                  infinite={true}
                  responsive={responsive}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup arrayCount={subs?.length} />}
                  keyBoardControl={true}
                  customTransition="transform 0.6s ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {subs?.map((dataa) => {
                    return (
                      <Box>
                        <Box
                          height={120}
                          width={120}
                          m={"30px auto"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          position={"relative"}
                          mb={"10px"}
                        >
                          <img
                            src={appImages.dottedLine}
                            width={"100%"}
                            className="rotating-box"
                            style={{ position: "absolute" }}
                          />
                          <Avatar
                            sx={{
                              height: 100,
                              width: 100,
                              position: "absolute",
                            }}
                            src={dataa?.image}
                          />
                        </Box>
                        <Typography
                          variant={"h6"}
                          fontSize={16}
                          fontWeight={600}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          {dataa?.name}
                          <a href={dataa?.authorUrl} target="_blank">
                            <LaunchIcon
                              sx={{
                                fontSize: 15,
                                color: appColor.black,
                                ml: "4px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </Typography>
                        <Typography
                          variant={"body1"}
                          fontSize={12}
                          textAlign={"center"}
                          mt={1}
                          sx={{ padding: { xs: "0px", sm: "0px 55px" } }}
                        >
                          {dataa?.text}
                        </Typography>
                      </Box>
                    );
                  })}
                </Carousel>
              </Grid>
            </Grid>
            <Box width={160} height={40} m={"30px auto"}>
              <CustomButton
                btnText={"Get Certificate"}
                textWeight={500}
                handleClickBtn={() =>
                  navigate("/facebook-giveaway/result", {
                    replace: true,
                  })
                }
              />
            </Box>
          </React.Fragment>
        )}
      </Container>
      {isLoading ? null : (
        <Container sx={{ maxWidth: "1200px !important" }}>
          <AppFooter />
        </Container>
      )}
    </AppHeader>
  );
}

export default FacebookWinnersAndSubstitutes;
