import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons, appImages } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import BussinessAccountDialog from "../../../../components/BussinessAccountDialog";
import CustomButton from "../../../../components/CustomButton";
import Loader from "../../../../components/Loader";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import InstaWave from "../../../../components/WhiteSoundWave";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import "./style.css";
import FacebookLogin from "react-facebook-login";

const AccessToken_Scopes = [
  "business_management",
  "email",
  "public_profile",
  "pages_read_engagement",
  "pages_read_user_content",
  "instagram_basic",
  "pages_show_list",
  "instagram_manage_comments",
];

let params = {
  giveawayType: "Instagram",
  dublicate: false,
  hashtag: false,
  block: false,
  extraChance: false,
  extraChanceNames: [],
  blockListNames: [],
  hashtagText: "",
  counter: 5,
  totalWinners: 1,
  totalSubstitutes: 0,
  actionType: "add",
  allComments: [],
  currentColor: "#ffffff",
  giveAwayId: "",
};

/* global FB */

function InstagramGiveaway() {
  const classes = GlobalStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [instagramLink, setInstagramLink] = useState("");
  const { setAllInstaPosts, setCurrentColor, setCountDown } =
    useContext(AppContext);
  const [dataLoading, setDataLoading] = useState(false);
  const [isByName, setIsByName] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setInstagramLink("");
  }, [isByName]);

  useEffect(() => {
    dispatch(setAppFilters(params));
    setCurrentColor("#fff");
    setCountDown(5);
  }, []);

  const handleNaviagteHome = () => {
    setOpenDialog(false);
    setIsByName(false);
  };

  const handleFacebookLogin = () => {
    if (FB.getAuthResponse()) {
      FB.logout(() => initiateFacebookLogin());
    } else {
      initiateFacebookLogin();
    }
  };

  const initiateFacebookLogin = () => {
    FB.login(handleLoginResponse, {
      scope:
        "business_management,email,public_profile,pages_read_engagement,pages_read_user_content,instagram_basic,pages_show_list,instagram_manage_comments",
    });
  };

  const handleLoginResponse = (response) => {
    if (response.status === "connected") {
      handleFetchUsersPages(response?.authResponse);
    } else {
      const message =
        response.status === "unknown"
          ? "We can not connect with your account. Please try again"
          : "Something went wrong during login.";
      handleSnackbarVal(true, "error", message);
    }
  };

  const handleFetchUsersPages = async (authResponse) => {
    const requiredFields =
      "id,name,access_token,picture,instagram_business_account{username,id,ig_id,followers_count}";
    const accessToken = authResponse?.accessToken;

    try {
      setDataLoading(true);
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/me/accounts?fields=${requiredFields}&access_token=${accessToken}`
      );
      setDataLoading(false);

      if (response?.data?.data?.length > 0) {
        // Filter the pages that have an Instagram Business Account linked
        const businessPages = response.data.data.filter((page) =>
          page.hasOwnProperty("instagram_business_account")
        );

        if (businessPages.length > 0) {
          navigate("/instagram-giveaway/instagram-accounts", {
            state: businessPages,
          });
        } else {
          handleSnackbarVal(
            true,
            "error",
            "It looks like your Instagram account is not a business account."
          );
        }
      } else {
        handleSnackbarVal(
          true,
          "error",
          "It looks like your Instagram account is not a business account."
        );
      }
    } catch (error) {
      console.error(error);
      setDataLoading(false);
      handleSnackbarVal(true, "error", "Something went wrong.");
    }
  };

  // const newWayToLoginFB = () => {
  //   const appId = "1722993894847227";
  //   const redirectUri = "http://localhost:3001/instagram-giveaway"; // Your redirect URI
  //   const scopes = [
  //     "business_management",
  //     "email",
  //     "public_profile",
  //     "pages_read_engagement",
  //     "pages_read_user_content",
  //     "instagram_basic",
  //     "pages_show_list",
  //     "instagram_manage_comments",
  //   ].join(",");

  //   const generateRandomState = () => {
  //     return (
  //       Math.random().toString(36).substring(2, 15) +
  //       Math.random().toString(36).substring(2, 15)
  //     );
  //   };

  //   const randomState = generateRandomState();

  //   const facebookAuthUrl = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${appId}&redirect_uri=${encodeURIComponent(
  //     redirectUri
  //   )}&scope=${scopes}&response_type=code&state=${randomState}`;

  //   // Redirect user to the Facebook Login URL
  //   window.location.href = facebookAuthUrl;
  // };

  return (
    <Box>
      <AppHeader>
        <Divider />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Loader isloading={isLoading} />
        {dataLoading ? (
          <AnimatedLoader />
        ) : (
          <Container
            sx={{
              maxWidth: "1250px !important",
              marginTop: { xs: "10px", md: "70px" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box position={"relative"}>
                  <Box
                    position={"absolute"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    gap={"10px"}
                    sx={{ bottom: { xs: "0px", sm: "15px" } }}
                  >
                    <Box
                      height={48}
                      width={48}
                      borderRadius={"50%"}
                      border={"2px solid black"}
                      position={"relative"}
                    >
                      {/* <img
                        src={
                          "https://raffleselect.com/static/media/likeAnimation.936b11a89e1fe22a265a.gif"
                        }
                        width={"100%"}
                        alt={"Animated Heart"}
                        style={{
                          position: "absolute",
                          zIndex: "-1",
                        }}
                      /> */}
                    </Box>
                    <Box
                      height={250}
                      width={55}
                      borderRadius={50}
                      bgcolor={appColor.instaPink}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      padding={"5px"}
                      gap={"10px"}
                    >
                      <img
                        src={appImages.instaStopButton}
                        alt="Button"
                        width={"90%"}
                      />
                      <Box>
                        <InstaWave />
                      </Box>
                    </Box>
                  </Box>
                  <Box margin={"auto"} sx={{ width: { xs: "70%", sm: "60%" } }}>
                    <img
                      src={appImages?.instagramHome}
                      alt="Insagram Image"
                      width={"100%"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"h4"} className={classes.rahomeh1}>
                  Instagram Comment Picker
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                  <Typography
                    variant={"h1"}
                    className={classes.rahomeh2}
                    sx={{ fontSize: { xs: 30, sm: 40, lg: 50 } }}
                  >
                    Our choice,
                  </Typography>
                  <Box width={"50px"}>
                    <img
                      src={appImages.roundedFlower}
                      alt="Flower"
                      width={"100%"}
                      className="round-flower"
                    />
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ gap: { xs: "2px", sm: "20px" } }}
                  mt={1}
                >
                  <Box sx={{ width: { xs: "40px", sm: "50px", lg: "70px" } }}>
                    <img
                      src={appImages.pinkEastArrow}
                      alt="arrow"
                      width={"100%"}
                    />
                  </Box>
                  <Typography
                    variant={"h1"}
                    className={classes.rahomeh2}
                    sx={{ fontSize: { xs: 30, sm: 40, md: 45, lg: 50 } }}
                  >
                    Your words
                  </Typography>
                </Stack>
                <Typography variant={"body1"} className={classes.rahomeh3}>
                  So, why wait? Join us in this journey where comments become
                  stars and where every voice has its chance to shine and claim
                  victory.
                </Typography>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <Box height={45} sx={{ width: { xs: "100%", md: "280px" } }}>
                    <CustomButton
                      startIcon={
                        <img
                          src={appIcons.instagramColorIcon}
                          width={"25px"}
                          style={{ zIndex: "999" }}
                        />
                      }
                      btnText={"Connect with Instagram"}
                      textWeight={400}
                      textSize={16}
                      iconSize={"15px !important"}
                      btnBackground={"#DD2A7B"}
                      handleClickBtn={handleFacebookLogin}
                      // handleClickBtn={newWayToLoginFB}
                    />
                  </Box>

                  {/* <FacebookLogin
                    appId="1722993894847227"
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="pages_show_list,instagram_basic,pages_read_engagement,pages_manage_posts" // zaroori permissions add karein
                    callback={(res) => {
                      console.log(res, "asdjhdasjhdashjdhasjdhjsdhajhdajdh");
                    }}
                  /> */}

                  {/* <UrlInput
                    placeholder={
                      !isByName
                        ? "Enter a Instagram Photo/Video URL"
                        : "Enter Instagram Username"
                    }
                    url={instagramLink}
                    setUrl={setInstagramLink}
                    handleClick={handleClick}
                  /> */}
                  {/* {isByName ? (
                    <Typography
                      variant={"h6"}
                      fontSize={13}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsByName(false)}
                    >
                      Or Enter Url
                    </Typography>
                  ) : (
                    <Typography
                      variant={"h6"}
                      fontSize={13}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsByName(true)}
                    >
                      Or Enter Username
                    </Typography>
                  )} */}
                </Stack>
                <Typography variant={"body1"} fontSize={12} mt={"14px"}>
                  🔗 Utilize this Instagram Giveaway picker to randomly choose
                  comments from Instagram photos and videos.
                </Typography>
              </Grid>
            </Grid>
            {!dataLoading && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{ marginTop: { xs: "20px", md: "0px" } }}
              >
                <Container
                  sx={{
                    maxWidth: "1200px !important",
                    position: { xs: "relative" },
                    bottom: 0,
                  }}
                >
                  <AppFooter />
                </Container>
              </Box>
            )}
          </Container>
        )}
        <BussinessAccountDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          handleNaviagteHome={handleNaviagteHome}
        />
      </AppHeader>
    </Box>
  );
}

export default InstagramGiveaway;
