import SaveIcon from "@mui/icons-material/Save";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import html2canvas from "html2canvas";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons } from "../../../../assets";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import CustomButton from "../../../../components/CustomButton";
import DownloadPicture from "../../../../components/DownloadPicture";
import Loader from "../../../../components/Loader";
import NewWinnerProfile from "../../../../components/NewWinnerProfileDialog";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import StackTemplate from "../../../../components/StackTemplate";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";
import {
  saveVideoHistory,
  updateVideoHistory,
} from "../../../../service/comments";
import { fetchWinnerData } from "../../../../service/common";
import CopyPostDialog from "./copyPostDialog";
import ReplaceFacebookWinner from "./ReplaceFacebookWinnerDialog";
import FacebookIcon from "@mui/icons-material/Facebook";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    backgroundColor: "#1B1D28",
    textAlign: "center",
    padding: "3px 5px",
    fontSize: 10,
    fontWeight: 300,
  },
}));

function FacebookGiveawayResult() {
  const navigate = useNavigate();
  const [mainTitle, setMainTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [winnerData, setWinnerData] = useState(null);
  const [isEditAble, setIsEditAble] = useState(false);
  const [openCopyPostDialog, setOpenCopyPostDialog] = useState(false);
  const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [replaceIndex, setReplaceIndex] = useState(0);
  const [isDataSave, setIsDataSave] = useState(false);
  const [openNewProfile, setOpenNewProfile] = useState(false);
  const { win, subs, currentColor, postData } = useContext(AppContext);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getWinnerData();
    setMainTitle(postData?.caption);
  }, []);

  const getWinnerData = () => {
    setIsLoading(true);
    fetchWinnerData()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setWinnerData(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleDownloadScreenshot = async () => {
    try {
      const currentSlide = document.querySelector(
        ".react-multi-carousel-item--active"
      );
      setIsLoading(true);
      handleSnackbarVal(true, "success", "Downlaoding..........");
      if (currentSlide) {
        setTimeout(async () => {
          const canvas = await html2canvas(currentSlide, {
            useCORS: true,
            logging: true,
            scrollX: 0,
            scrollY: 0,
          });

          const image = canvas.toDataURL("image/jpeg");
          const link = document.createElement("a");
          link.href = image;
          link.download = "captured.jpg";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsLoading(false);
          handleSnackbarVal(true, "success", " Image Downloaded Successfully");
        }, 1000);
      } else {
        handleSnackbarVal("Error While Download Story");
      }
    } catch (error) {
      handleSnackbarVal("Error While Download Story:");
    }
  };

  const handleDelete = () => {
    navigate("/");
  };

  const handleSaveData = () => {
    if (filterData?.actionType == "add") {
      let payload = {
        currentColor,
        winners: win,
        substitutes: subs,
        userId: userData?._id,
        videoTitle: mainTitle,
        counter: filterData?.counter,
        ishashtag: filterData?.hashtag,
        viewCount: postData?.viewCount || 0,
        likeCount: postData?.like_count || 0,
        videoLink: postData?.permalink || "",
        isBlockList: filterData?.block,
        channelName: postData?.username || "",
        isDublicate: filterData?.dublicate,
        videoThumbnail: postData?.media_url,
        isExtraBonus: filterData?.extraChance,
        commentCount: postData?.comments_count || 0,
        winnersCount: filterData?.totalWinners,
        hashtagValue: filterData?.hashtagText,
        blockListNames: filterData?.blockListNames,
        extraBonusNames: filterData?.extraChanceNames,
        substitutesCount: filterData?.totalSubstitutes,
        giveawayType: filterData?.giveawayType,
        contentId: postData?.id,
        createdAt: moment(new Date()).format("DD-MMMM-YYYY"),
      };

      console.log(payload, "assdskklkasdkldasdkldwdidjkasdasjkdsjk");
      // return;
      setIsLoading(true);
      saveVideoHistory(payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            // setIsDataSave(true);
            handleSnackbarVal(true, "success", res?.data?.message);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1500);
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", "Something went wrong");
        });
    } else {
      let payload = {
        currentColor,
        winners: win,
        substitutes: subs,
        videoTitle: mainTitle,
      };
      setIsLoading(true);
      updateVideoHistory(filterData?.giveAwayId, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            setIsDataSave(true);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1500);
            handleSnackbarVal(true, "success", res?.data?.message);
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", "Something went wrong");
        });
    }
  };

  const handleSaveTitle = () => {
    setIsEditAble(false);
    let newObj = { ...postData, title: mainTitle };
    handleSnackbarVal(true, "success", "Title Edited Successfully");
    localStorage.setItem("twitter-post", JSON.stringify(newObj));
  };

  const handleCloseDialog = () => {
    setOpenCopyPostDialog(false);
  };

  const handleCloseReplaceDialog = () => {
    setOpenReplaceDialog(false);
    setDialogData(null);
  };

  const handleCloseNewWinner = () => {
    setOpenNewProfile(false);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <AppHeader>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Divider />
      <Container sx={{ maxWidth: "1100px !important", mt: "8px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box
              bgcolor={"#ffffff"}
              borderRadius={"20px"}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
            >
              <StackTemplate stackProps={{ p: "10px", spacing: 1 }}>
                <TextField
                  type={"text"}
                  size={"small"}
                  value={mainTitle}
                  onChange={(e) => setMainTitle(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: isEditAble ? "block" : "none",
                    },
                  }}
                  InputProps={{
                    readOnly: isEditAble ? false : true,
                  }}
                  fullWidth
                />
                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                  {isEditAble !== true ? (
                    <img
                      width={16}
                      alt={"edit button"}
                      src={appIcons?.editIcon}
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditAble(true)}
                    />
                  ) : (
                    <SaveIcon
                      sx={{
                        color: "#666666",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                      onClick={handleSaveTitle}
                    />
                  )}
                  <img
                    width={16}
                    alt={"delete button"}
                    onClick={handleDelete}
                    src={appIcons?.deleteIcon}
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </StackTemplate>
              <Divider />
              <Stack p={"10px"}>
                <Typography
                  variant={"h6"}
                  fontSize={18}
                  fontWeight={600}
                  color={appColor?.black}
                  fontFamily={"Roboto Flex"}
                >
                  {winnerData?.boxHeading1 ? winnerData?.boxHeading1 : ""}
                </Typography>
              </Stack>
              <Divider />
              <Stack p={"12px"}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <img
                    width={16}
                    alt={"Instagram icon"}
                    src={appIcons?.instagramIcon}
                  />

                  <Typography
                    variant={"h6"}
                    fontSize={15}
                    fontWeight={500}
                    color={appColor.black}
                    fontFamily={"Roboto Flex"}
                  >
                    Post a comment
                  </Typography>
                </Stack>
                <Typography
                  ml={"24px"}
                  fontSize={11}
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                >
                  Comment on the giveaway post mentioning the winners
                </Typography>

                <Box width={90} height={30} m={"8px 0 0 auto"}>
                  <CustomButton
                    textSize={10}
                    btnText={"Comments"}
                    iconSize={"15px !important"}
                    handleClickBtn={() => setOpenCopyPostDialog(true)}
                  />
                </Box>
              </Stack>
              <Divider />
              <Box p={"15px 0 15px 0 "}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} textAlign={"center"}>
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"3px"}
                    >
                      <img
                        width={20}
                        src={appIcons?.captureCamera}
                        alt={"capture camera icon"}
                      />
                      <Typography
                        fontSize={14}
                        variant={"h6"}
                        fontWeight={600}
                        lineHeight={"12px"}
                        color={appColor.black}
                        fontFamily={"Roboto"}
                      >
                        Story Certificate (.jpg)
                      </Typography>
                    </Stack>
                    <Link
                      mb={2}
                      fontSize={13}
                      fontWeight={500}
                      color={"#1377F8"}
                      sx={{ cursor: "pointer" }}
                      fontFamily={"Roboto Flex"}
                      onClick={handleDownloadScreenshot}
                    >
                      Download Image
                    </Link>
                    <Box className={"certificate-crousal"}>
                      <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        renderDotsOutside={true}
                        arrows={true}
                        infinite={true}
                        responsive={responsive}
                        keyBoardControl={true}
                        transitionDuration={500}
                        customTransition="transform 0.6s ease-in-out"
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {win?.map((dataa) => {
                          return (
                            <Box
                              height={225}
                              width={145}
                              m={"0px auto"}
                              mt={"10px"}
                            >
                              <DownloadPicture
                                certificateData={{
                                  name: dataa?.name,
                                  image: dataa?.image,
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Carousel>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} textAlign={"center"}>
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"3px"}
                    >
                      <img
                        width={20}
                        src={appIcons?.vlogCamera}
                        alt={"capture camera icon"}
                      />
                      <Typography
                        variant={"h6"}
                        fontSize={14}
                        fontWeight={600}
                        lineHeight={"12px"}
                        color={appColor?.black}
                        fontFamily={"Roboto"}
                      >
                        Story Animation (.mp4)
                      </Typography>
                    </Stack>
                    <Link
                      fontSize={13}
                      fontWeight={600}
                      color={"#1377F8"}
                      fontFamily={"Roboto Flex"}
                      sx={{ cursor: "pointer" }}
                    >
                      Download Video
                    </Link>
                    <Box className={"certificate-crousal"}>
                      <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        arrows={true}
                        infinite={true}
                        responsive={responsive}
                        renderDotsOutside={true}
                        keyBoardControl={true}
                        transitionDuration={500}
                        customTransition="transform 0.6s ease-in-out"
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        beforeChange={(nextSlide, { currentSlide, onMove }) => {
                          console.log(currentSlide - 1, "sadasdasdasddsa");
                        }}
                      >
                        {win?.map((dataa) => {
                          return (
                            <Box
                              height={225}
                              width={145}
                              m={"0px auto"}
                              mt={"10px"}
                            >
                              <DownloadPicture
                                certificateData={{
                                  name: dataa?.name,
                                  image: dataa?.image,
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Carousel>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  bgcolor={"#ffffff"}
                  borderRadius={"20px"}
                  boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
                >
                  <Stack p={"10px"} direction={"column"}>
                    <Typography
                      variant={"h6"}
                      fontSize={18}
                      fontWeight={600}
                      color={appColor.black}
                      fontFamily={"Roboto Flex"}
                    >
                      {winnerData?.boxHeading3 ? winnerData?.boxHeading3 : ""}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack p={"10px"} direction={"column"}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          fontSize={12}
                          fontWeight={700}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                        >
                          Type
                        </Typography>
                        <Stack
                          mt={"1px"}
                          spacing={"6px"}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          {/* <img
                            width={13}
                            height={13}
                            src={appIcons?.facebookOutline}
                            alt={"Youtube icon"}
                          /> */}
                          <FacebookIcon htmlColor="#1877F2" />
                          <Typography
                            fontSize={12}
                            fontWeight={400}
                            variant={"body1"}
                            fontFamily={"Roboto Flex"}
                          >
                            Facebook Giveaway
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          fontSize={12}
                          fontWeight={700}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                        >
                          Author
                        </Typography>

                        <Link
                          fontSize={12}
                          fontWeight={400}
                          fontFamily={"Roboto Flex"}
                        >
                          {postData?.username}
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          fontSize={12}
                          fontWeight={700}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                        >
                          Participants
                        </Typography>

                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                        >
                          {postData?.comments_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8} overflow={"hidden"}>
                        <Typography
                          fontSize={12}
                          fontWeight={700}
                          variant={"body1"}
                          lineHeight={"10px"}
                          fontFamily={"Roboto Flex"}
                        >
                          Post
                        </Typography>

                        <Link
                          fontSize={12}
                          fontWeight={400}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                          sx={{ cursor: "pointer" }}
                          href={postData?.permalink}
                          target="_blank"
                          // onClick={() =>
                          //   window.open(videoData?.youtubeLink, "_blank")
                          // }
                        >
                          {postData?.permalink}
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                          fontSize={12}
                          fontWeight={700}
                        >
                          Date
                        </Typography>

                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                        >
                          {postData?.createdAt ||
                            moment(new Date()).format("DD-MM-YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        p={"10px"}
                        fontSize={14}
                        fontWeight={600}
                        variant={"body1"}
                        fontFamily={"Roboto Flex"}
                      >
                        Winners 🏆
                      </Typography>
                      {win?.map((dataa, index) => {
                        return (
                          <>
                            <Stack
                              direction={"row"}
                              spacing={1.5}
                              p={"10px"}
                              // pb={"30px"}
                            >
                              <Avatar
                                // src={dataa?.image}
                                alt={"Person Image"}
                                sx={{ height: 35, width: 35 }}
                              >
                                {dataa?.userName?.charAt(0)?.toUpperCase()}
                              </Avatar>
                              <Stack alignItems={"flex-start"}>
                                <Typography
                                  variant={"body1"}
                                  fontFamily={"Roboto Flex"}
                                  fontSize={14}
                                  fontWeight={600}
                                >
                                  {dataa?.name}
                                </Typography>
                                <Stack
                                  direction={"row"}
                                  alignItems={"baseline"}
                                  spacing={1}
                                  // ml={"-36px"}
                                >
                                  <BootstrapTooltip
                                    title={"replace"}
                                    sx={{ fontSize: 14 }}
                                  >
                                    <img
                                      src={appIcons.replaceIcon}
                                      alt={"replace icon"}
                                      width={14}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (subs.length > 0) {
                                          setOpenReplaceDialog(true);
                                          setDialogData(dataa?.name);
                                          setReplaceIndex(index);
                                        } else {
                                          handleSnackbarVal(
                                            true,
                                            "error",
                                            "No Alternates Found"
                                          );
                                        }
                                      }}
                                    />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip title={dataa?.text}>
                                    <img
                                      src={appIcons.commentIcon}
                                      alt={"comment icon"}
                                      width={14}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    // title={`https://www.facebook.com//${dataa?.userName}`}
                                    title={dataa?.authorUrl}
                                  >
                                    <a
                                      //   href={`https://www.facebook.com//${dataa?.userName}`}
                                      href={dataa?.authorUrl}
                                      target="_blank"
                                    >
                                      <img
                                        src={appIcons.linkIcon}
                                        alt={"link icon"}
                                        width={14}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>
                                  </BootstrapTooltip>
                                </Stack>
                              </Stack>
                            </Stack>
                            <Divider />
                          </>
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {subs.length > 0 ? (
                        <Typography
                          p={"10px"}
                          fontSize={14}
                          fontWeight={600}
                          variant={"body1"}
                          fontFamily={"Roboto Flex"}
                        >
                          Alternates 🎖
                        </Typography>
                      ) : null}
                      {subs?.map((dataa) => {
                        return (
                          <>
                            <Stack
                              direction={"row"}
                              spacing={1.5}
                              p={"10px"}
                              // pb={"30px"}
                            >
                              <Avatar
                                // src={dataa?.image}
                                alt={"Person Image"}
                                sx={{ height: 35, width: 35 }}
                              >
                                {dataa?.userName?.charAt(0)?.toUpperCase()}
                              </Avatar>
                              <Stack alignItems={"flex-start"}>
                                <Typography
                                  variant={"body1"}
                                  fontFamily={"Roboto Flex"}
                                  fontSize={14}
                                  fontWeight={600}
                                >
                                  {dataa?.name}
                                </Typography>
                                <Stack
                                  direction={"row"}
                                  alignItems={"baseline"}
                                  spacing={1}
                                  // ml={"-36px"}
                                >
                                  <BootstrapTooltip title={dataa?.text}>
                                    <img
                                      src={appIcons.commentIcon}
                                      alt={"comment icon"}
                                      width={14}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    // title={`https://www.facebook.com//${dataa?.userName}`}
                                    title={dataa?.authorUrl}
                                  >
                                    <Link
                                      //   href={`https://www.facebook.com//${dataa?.userName}`}
                                      href={dataa?.authorUrl}
                                      target="_blank"
                                    >
                                      <img
                                        src={appIcons.linkIcon}
                                        alt={"link icon"}
                                        width={14}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </Link>
                                  </BootstrapTooltip>
                                </Stack>
                              </Stack>
                            </Stack>
                            <Divider />
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Stack direction={"row"} justifyContent={"flex-end"} p={2}>
                    <Box width={"120px"} height={40}>
                      <CustomButton
                        btnText={
                          filterData?.actionType == "add" ? "save" : "update"
                        }
                        btnDisable={isDataSave ? true : false}
                        textSize={15}
                        handleClickBtn={handleSaveData}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <CopyPostDialog
        open={openCopyPostDialog}
        handleClose={handleCloseDialog}
        handleSnackbarVal={handleSnackbarVal}
      />
      <ReplaceFacebookWinner
        open={openReplaceDialog}
        handleClose={handleCloseReplaceDialog}
        dialogData={dialogData}
        replaceIndex={replaceIndex}
        handleSnackbarVal={handleSnackbarVal}
        setOpenNewProfile={setOpenNewProfile}
      />
      <NewWinnerProfile
        open={openNewProfile}
        handleClose={handleCloseNewWinner}
      />
      <Container sx={{ maxWidth: "1200px !important" }}>
        <AppFooter />
      </Container>
    </AppHeader>
  );
}

export default FacebookGiveawayResult;
