import React, { useContext, useState } from "react";
import AppHeader from "./../../../../components/AppHeader";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import StackTemplate from "../../../../components/StackTemplate";
import { appFonts } from "../../../../constants/Fonts";
import InstaPostStyle from "./Style.js";
import { appIcons } from "../../../../assets/index.js";
import { appColor } from "../../../../constants/Colors";
import { useNavigate } from "react-router-dom";

const AllCounts = ({ icon, count }) => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <img src={icon} width={"23%"} height={"23%"} alt={"icon"} />
      <Typography variant={"body2"} color={appColor.white} fontSize={12}>
        {count}
      </Typography>
    </Box>
  );
};

function AllUserPosts() {
  const navigate = useNavigate();
  const globalClass = GlobalStyle();
  const classes = InstaPostStyle();
  const { allInstaPosts, setPostData } = useContext(AppContext);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });


  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };
  return (
    <AppHeader>
      <Divider />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <StackTemplate
        stackProps={{
          justifyContent: "center",
          gap: "20px",
          margin: "30px 0 10px 0",
        }}
      >
        <Avatar
          src={allInstaPosts?.profile_picture_url}
          alt={allInstaPosts?.username}
          sx={{
            height: 50,
            width: 50,
          }}
        />
        <Typography variant={"h1"} className={globalClass.raDetailH1}>
          hello, {allInstaPosts?.username}
        </Typography>
      </StackTemplate>
      <Typography
        variant={"body1"}
        textAlign={"center"}
        fontSize={14}
        fontFamily={appFonts?.RobotoFlex}
      >
        Please, select a post
      </Typography>
      <Container sx={{ maxWidth: { sm: "1000px !important", lg: "lg" } }}>
        <Grid container spacing={2} mt={4}>
          {allInstaPosts?.media?.data?.map((dataa, index) => {
            return (
              <Grid item xs={12} sm={4} md={3} key={index}>
                <Box
                  className={classes.instaCrad}
                  onClick={() => {
                    navigate("/instagram-giveaway/post-detail");
                    setPostData({
                      ...dataa,
                      profile_picture_url: allInstaPosts?.profile_picture_url,
                      type: "single",
                    });
                    console.log(dataa, "dajhdasdasdhasdjhas");
                  }}
                >
                  <StackTemplate
                    stackProps={{
                      position: "absolute",
                      justifyContent: "center !important",
                    }}
                  >
                    <AllCounts
                      icon={appIcons?.commentWhite}
                      count={dataa?.comments_count}
                    />
                    <AllCounts
                      icon={appIcons?.heartWhite}
                      count={dataa?.like_count}
                    />
                    <AllCounts
                      icon={appIcons?.shareWhite}
                      count={dataa?.comments_count}
                    />
                  </StackTemplate>
                  <img
                    src={
                      dataa?.thumbnail_url
                        ? dataa?.thumbnail_url
                        : dataa?.media_url
                    }
                    alt={dataa?.caption}
                    className={classes.instaCradImg}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </AppHeader>
  );
}

export default AllUserPosts;
