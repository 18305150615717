import React, { useContext, useState } from "react";
import AppHeader from "../../../components/AppHeader";
import { Container, Divider } from "@mui/material";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { AppContext } from "../../../context";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);
  const { privacyAndTerms } = useContext(AppContext);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };
  return (
    <AppHeader>
      <Divider />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Container sx={{ maxWidth: "1200px !important", marginTop: "20px" }}>
        <div dangerouslySetInnerHTML={{ __html: privacyAndTerms?.about }}></div>
      </Container>
    </AppHeader>
  );
}

export default AboutUs;
